import { createSlice } from '@reduxjs/toolkit';

const attributeSlice = createSlice({
  name: 'attributes',
  initialState: {
    attributes: [],
    loading: false,
    error: null
  },
  reducers: {
    setAttributes: (state, action) => {
      state.attributes = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setAttributes, setLoading, setError } = attributeSlice.actions;
export default attributeSlice.reducer;
export const selectAttributes = (state) => state.attributes.attributes;
export const selectLoading = (state) => state.attributes.loading;