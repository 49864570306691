import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { apiSlice } from "../services/api";
import AuthReducers from "../reducers/AuthReducers";
import { attributesReducers, cartReducers, categoryReducers, childCategoryReducers, companyReducers, orderReducers, productReducers, subCategoryReducers } from "../reducers";
import brandsReducers from "../reducers/brandsReducers";
import topCorauselReducers from "../reducers/topCorauselReducers";
import bottomCorauselsReducers from "../reducers/bottomCorauselReducer";
import mainCorauselsReducers from "../reducers/mainCorauselReducers";
import recommendedsReducers from "../reducers/recommendedReducers";
import productBySlugReducers from "@/reducers/productBySlugReducers";
import AddressReducer from "@/reducers/AddressReducer";
import pickuppointReducers from "@/reducers/pickuppointReducers";
import wishlistReducer from "@/reducers/WishlistReducer";


// Cart persistence configuration
const cartPersistConfig = {
  key: "cart",
  storage,
  whitelist: ["cart", "selectedPickupPoint", "selectedCountyId", "selectedDeliveryMethod", "grandTotal", "deliveryMethod", "total", "subTotal", "shipping", "tax", "discount", "serviceFee", "totalItems", "paymentMethod"],
  stateReconciler: autoMergeLevel2,
};
// Persist configuration for the auth reducer
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "activeRole", "selectedItem", "isAuthenticated", "projectId", "isLoggedIn", "roles", "user", "refreshToken", "email", "otp"],
  stateReconciler: autoMergeLevel2,
};
const wishlistPersistConfig = {
  key: "wishlist",
  storage,
  whitelist: ['wishlist'],
  stateReconciler: autoMergeLevel2,
};




const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducers),
   companies: companyReducers,
  orders: orderReducers,
  products: productReducers,
  product: productBySlugReducers,
  brands: brandsReducers,
  categories: categoryReducers,
  subcategories: subCategoryReducers,
  childcategories: childCategoryReducers,
  attributes: attributesReducers,
  topCorausels: topCorauselReducers,
  mainCorausels: mainCorauselsReducers,
  bottomCorausels: bottomCorauselsReducers,
  recommendeds: recommendedsReducers,
  addresses: AddressReducer,
  pickups: pickuppointReducers,
  cart: persistReducer(cartPersistConfig, cartReducers),
  wishlist: persistReducer(wishlistPersistConfig, wishlistReducer),
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializableCheck
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

export const persistor = persistStore(store);
