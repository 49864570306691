import { createSlice } from '@reduxjs/toolkit';

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    wishlist: [],
    loading: false,
    error: null
  },
  reducers: {
    setwishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    addToWishlist: (state, action) => {
      state.wishlist.push(action.payload);  
    },
    updateWishlist: (state, action) => {
      const index = state.wishlist.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.wishlist[index] = action.payload;  
      }
    },
    removeFromWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(item => item.id !== action.payload);  
    }
  }
});

export const {
  setwishlist,
  setLoading,
  setError,
  addToWishlist,
  updateWishlist,
  removeFromWishlist
} = wishlistSlice.actions;

export default wishlistSlice.reducer;

export const selectwishlist = (state) => state.wishlist.wishlist;
export const selectLoading = (state) => state.wishlist.loading;
export const selectError = (state) => state.wishlist.error;
