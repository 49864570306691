import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subcategories: [],
  loading: false,
  error: null,
};

const subcategorySlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {
  
    setSubCategories(state, action) {
      state.subcategories = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setSubCategories, setLoading, setError } = subcategorySlice.actions;
export default subcategorySlice.reducer;
export const selectSubcategories = (state) => state.subcategories.subcategories;
export const selectLoading = (state) => state.subcategories.loading;
export const selectError = (state) => state.subcategories.error;