import { createSlice } from '@reduxjs/toolkit';

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    brands: [],
    loading: false,
    error: null
  },
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setBrands, setLoading, setError } = brandsSlice.actions;
export default brandsSlice.reducer;
export const selectBrands = (state) => state.brands.brands
