import { createSlice } from '@reduxjs/toolkit';

const PickUpApiSlice = createSlice({
  name: 'pickups',
  initialState: {
    pickups: [],
    counties: [],
    loading: false,
    error: null
  },
  reducers: {
    setPickups: (state, action) => {
      state.pickups = action.payload;
    },
    setCounties: (state, action) => {
      state.counties = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setPickups, setCounties, setLoading, setError } = PickUpApiSlice.actions;
export default PickUpApiSlice.reducer;

export const selectPickups = (state) => state.pickups.pickups;
export const selectCounties = (state) => state.pickups.counties
