import { createSlice } from '@reduxjs/toolkit';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    loading: false,
    error: null
  },
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setCompanies, setLoading, setError } = companiesSlice.actions;
export default companiesSlice.reducer;
export const selectCompanies = (state) => state.companies.companies;