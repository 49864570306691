import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    loading: false,
    error: null
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setOrders, setLoading, setError } = orderSlice.actions;
export default orderSlice.reducer;
// selectors
export const selectOrders = (state) => state.orders.orders;
export const selectLoading = (state) => state.orders.loading;
export const selectError = (state) => state.orders.error;
export const selectPendingOrders = (state) =>
  state.orders.orders.filter(product => product.status === 'pending');