"use client"; // Mark this as a client-side component


import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../store/store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { SocketProvider } from "./SocketContext";

export default function ClientProvider({ children }) {
  return (
    <Provider store={store}>
      <SocketProvider>
        {/* You can add a loading spinner or any fallback UI here */}
        <PersistGate loading={null} persistor={persistor}>
          {children}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            theme="light" // You can use 'dark' or 'colored' if desired
          />
        </PersistGate>
      </SocketProvider>
    </Provider>
  );
}
