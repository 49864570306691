import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'productbyslug',
  initialState: {
    product: [],
    loading: false,
    error: null
  },
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },

  }
});

export const { setProduct, setDelete, setLoading, setError } = productSlice.actions;
export default productSlice.reducer;
export const selectProduct = (state) => state.product.product;
