import { createSlice } from '@reduxjs/toolkit';

const addressesSlice = createSlice({
    name: 'addresses',
    initialState: {
        addresses: [],
        loading: false,
        error: null
    },
    reducers: {
        setAddresses: (state, action) => {
            state.addresses = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses.push(action.payload);
        },
        updateAddress: (state, action) => {
            const updatedAddress = action.payload;

            // Set all addresses' isDefault to false
            state.addresses.forEach(address => {
                address.isDefault = false;
            });

            // Update the specific address to be the default
            const index = state.addresses.findIndex(address => address.id === updatedAddress.id);
            if (index !== -1) {
                state.addresses[index] = { ...state.addresses[index], ...updatedAddress, isDefault: true };
            }
        },
        deleteAddress: (state, action) => {
            state.addresses = state.addresses.filter(address => address.id !== action.payload);
        }
    }
});

// Exporting the actions for use in components
export const { setAddresses, setLoading, setError, addAddress, updateAddress, deleteAddress } = addressesSlice.actions;

// Selectors
export const selectAddresses = (state) => state.addresses.addresses;
export const selectLoading = (state) => state.addresses.loading;
export const selectError = (state) => state.addresses.error; // Optional: You can also export the error selector

// Exporting the reducer
export default addressesSlice.reducer;
