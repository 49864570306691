import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  childcategories: [],
  loading: false,
  error: false,
};

const childcategorySlice = createSlice({
  name: 'childcategories',
  initialState,
  reducers: {
  
    setChildCategories(state, action) {
      state.childcategories = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDelete(state, action) {
      const id = action.payload;
      state.childcategories = state.childcategories.filter(childcategory => childcategory.id !== id);
console.log("called");
    },
  },
});


export const { setChildCategories, setLoading, setError,setDelete } = childcategorySlice.actions;
export default childcategorySlice.reducer;
export const selectChildcategories = (state) => state.childcategories.childcategories;
export const selectLoading = (state) => state.childcategories.loading;
export const selectError = (state) => state.childcategories.error;